import moment from "moment-timezone"; 
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";
import loader from "../../../../assets/images/loader.svg";
import { ButtonDialog } from "../../../../components/button-dialog/ButtonDialog";
import "./DialogEditSchedule.scss";
import 'react-datetime/css/react-datetime.css';
import CustomDateField from "../../../../components/CustomDateField/CustomDateField";

export class DialogEditSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleEdit: {
        ...this.props.scheduleEdit,
        dockId: this.props.dockSelected.oid,
      },
      operationType: [],
      packagingType: [],
      vehicleType: [],

      listOpDocaAux: null,
      // operationTime: 60,
      scheduleEnable: false,
      //NAO CHEGOU, NO PATIO, CARREGADO
      loadingStatusList: [
        { code: 0, name: "VEÍCULO NÂO CHEGOU" },
        { code: 1, name: "VEÍCULO NO PÁTIO" },
        { code: 2, name: "VEÍCULO CARREGADO" },
        { code: 3, name: "VEÍCULO VAZIO" },
        { code: 4, name: "SEM OPERAÇÃO" }
      ],

      loadingStatus: { code: 0, name: "VEÍCULO NÂO CHEGOU" },
    };
    this.formatHour = "HH:mm";
    this.setLimit = false;
  }

  componentDidMount() {
    try {
      moment.tz.setDefault("America/Sao_Paulo");
      let shipmentCodeAux = "";
      if (this.props.scheduleEdit && this.props.scheduleEdit.shipmentCode && this.props.scheduleEdit.shipmentCode.length > 0) {
        shipmentCodeAux = this.props.scheduleEdit.shipmentCode.join(",")
      }
      let scheduleEdit = {
        ...this.props.scheduleEdit,
        dockId: this.props.dockSelected.oid,
      };

      //extrair configurações da doca
      const { docaVehicleOperation = [] } = this.props.dockSelected;
      let listOpDocaAux = [];
      let packagingType = [];
      for (const op of docaVehicleOperation) {
        if (op.packagingType && op.packagingType.length > 0) {
          op.packagingType.forEach((t, index) => {
            if (packagingType.filter((it) => it.oid == t.oid).length == 0) {
              packagingType.push(t);
              listOpDocaAux.push(op);
            }
          });
        }
      }

      if (!scheduleEdit.scheduleDate) {
        scheduleEdit.scheduleDate = this.props.dateSelected;
      }

      let operationType = [];
      let vehicleType = [];
      let operationTime = 15;
      //tempo de tolerancia entre Operações
      operationTime += this.props.docaWorkDayTime.timeNextOperation;
      if (scheduleEdit.oid) {
        operationType.push(scheduleEdit.operationType);
        vehicleType.push(scheduleEdit.vehicleType);
        operationTime = scheduleEdit.operationTime;
      }

      try {
        if (!scheduleEdit.startAt) {
          scheduleEdit.startAt = this.props.horaSelected;
        }
        if (!scheduleEdit.endAt) {
          scheduleEdit.endAt = moment(scheduleEdit.startAt).add(operationTime, "minute").toDate();
        }
      } catch (error) {
        console.log("erro ", error);
      }

      if (!scheduleEdit.businessUnit) {
        scheduleEdit.businessUnit = {
          company: this.props.company,
          documentNumber: this.props.currentBusinessUnit.businessUnit,
          sigla: this.props.currentBusinessUnit.sigla,
        };
      }

      if (!scheduleEdit.carrier && this.props.selectedCarriers.length > 0) scheduleEdit.carrier = this.props.selectedCarriers[0];

      let loadingStatus = { code: 0, name: "VEÍCULO NÂO CHEGOU" }
      if (scheduleEdit.loadingStatus) {
        let lodStats = this.state.loadingStatusList.find(s => s.code == scheduleEdit.loadingStatus)
        console.log("lodStats=", lodStats)
        if (lodStats) {
          loadingStatus = lodStats;
        }
      }

      this.setState({
        scheduleEdit,
        listOpDocaAux,
        packagingType,
        vehicleType: vehicleType,
        operationType: operationType,
        operationTime: operationTime,
        scheduleEnable: this.props.scheduleLimite > 0 || scheduleEdit.oid,
        shipmentCodeAux,
        loadingStatus
      });
    } catch (error) {
      console.log("componentDidMount erro:", error);
    }
  }

  componentDidUpdate() {
    if (this.props.scheduleLimite && !this.setLimit && !this.state.scheduleEdit.oid) {
      let limite = this.props.scheduleLimite;
      if (limite > 60) limite = 60; //default

      if (!this.state.scheduleEdit.operationTime || this.state.scheduleEdit.operationTime > limite) {
        this.setLimit = true;
        let scheduleEdit = this.state.scheduleEdit;
        scheduleEdit.operationTime = limite;
        scheduleEdit.endAt = moment(scheduleEdit.startAt).add(scheduleEdit.operationTime, "minute").toDate();
        this.setState({ scheduleEdit, scheduleEnable: this.props.scheduleLimite > 0 || scheduleEdit.oid });
      }
    }
  }

  getTitulo = () => {
    let titulo = this.props.scheduleEdit ? "Editar Agenda" : "Nova Agenda";
    titulo += " - ";
    titulo += this.props.dockSelected.name;
    return titulo;
  };

  onDropdownCarriersChange = (e) => {
    const { carrier, name } = e.target.value;
    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.carrier = { carrier, name };
    this.setState({ scheduleEdit });
  };

  onChangeNrmShipmentCode = (e) => {
    let shipmentCodeAux = this.state.shipmentCodeAux;
    const inputValue = e.currentTarget.value;
    shipmentCodeAux = inputValue.replace(/[^0-9,]/g, '');
    const listShips = String(shipmentCodeAux).split(",")
    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.shipmentCode = listShips.filter(s => s != "")
    this.setState({ shipmentCodeAux, scheduleEdit });
  };

  onDropdownPackagingTypeChange = (e) => {
    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.packagingType = e.target.value;
    this.setState({ scheduleEdit });

    setTimeout(() => {
      this.onFilterVehicle();
    }, 500);
  };

  onDropdownOperationTypeChange = (e) => {
    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.operationType = e.target.value;
    this.setState({ scheduleEdit });

    setTimeout(() => {
      this.onFilterOperationTime();
    }, 300);
  };

  //
  onFilterVehicle = () => {
    const { docaVehicleOperation = [] } = this.props.dockSelected;
    let vehicleType = [];
    let listOpDocaAux = [];
    for (const op of docaVehicleOperation) {
      if (op.packagingType && op.packagingType.length > 0 && this.state.scheduleEdit.packagingType) {
        op.packagingType.forEach((t) => {
          if (this.state.scheduleEdit.packagingType.oid == t.oid) {
            if (op.vehicleType) {
              if (vehicleType.filter((it) => it.oid == op.vehicleType.oid).length == 0) {
                vehicleType.push(op.vehicleType);
                listOpDocaAux.push(op);
              }
            }
          }
        });
      }
    }
    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.operationTime = 0;
    scheduleEdit.vehicleType = null;
    this.setState({ listOpDocaAux, vehicleType, operationType: [], scheduleEdit });
  };

  onDropdownVehicleTypeChange = (e) => {
    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.vehicleType = e.target.value;
    this.setState({ scheduleEdit });

    setTimeout(() => {
      this.onFilterOperationTypeAfterVehicle();
    }, 500);
  };

  onFilterOperationTypeAfterVehicle = () => {
    let operationType = [];
    let listOpDocaAux = [];
    //não pode existir valores conflitante
    //não pode existir dois registro com mesmo veiculo e mesmo embalagem e mesmo tipo operacao
    if (this.state.listOpDocaAux) {
      for (const op of this.state.listOpDocaAux) {
        if (op.operationType && op.operationType.length > 0) {
          op.operationType.forEach((t) => {
            if (op.vehicleType.oid == this.state.scheduleEdit.vehicleType.oid) {
              if (operationType.filter((it) => it.type == t.type).length == 0) {
                operationType.push(t);
                listOpDocaAux.push(op);
              }
            }
          });
        }
      }
    }
    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.operationTime = 0;
    scheduleEdit.operationType = null;
    this.setState({ operationType, scheduleEdit });
  };

  onFilterOperationTime = () => {
    let operationTime = 0;

    //não pode existir valores conflitante
    //não pode existir dois registro com mesmo veiculo e mesmo embalagem e mesmo tipo operacao
    if (this.state.listOpDocaAux) {
      for (const op of this.state.listOpDocaAux) {
        if (op.operationType && op.operationType.length > 0 && this.state.scheduleEdit.operationType) {
          op.operationType.forEach((t) => {
            if (t.type == this.state.scheduleEdit.operationType.type) {
              operationTime = parseInt(op.operationTime);
            }
          });
        }
      }
    }

    let scheduleEdit = this.state.scheduleEdit;
    scheduleEdit.operationTime = operationTime;
    scheduleEdit.endAt = moment(this.props.horaSelected)
      .add(operationTime + this.props.docaWorkDayTime.timeNextOperation, "minute")
      .toDate();

    this.setState({ scheduleEdit });
  };

  onChangeOperationTime = (e) => {
    let scheduleEdit = this.state.scheduleEdit;
    let operationTime = parseInt(e.target.value);
    //tempo de tolerancia entre Operações
    // operationTime += this.props.docaWorkDayTime.timeNextOperation;
    if (!this.state.scheduleEdit.oid) {
      if (this.props.scheduleLimite && operationTime > this.props.scheduleLimite) {
        operationTime = this.props.scheduleLimite;
      }
    }
    scheduleEdit.operationTime = operationTime;
    scheduleEdit.endAt = moment(scheduleEdit.startAt).add(scheduleEdit.operationTime, "minute").toDate();
    this.setState({ scheduleEdit });
  };

  onChangeQtdeSacos = (e) => {
    try {
      let scheduleEdit = this.state.scheduleEdit;
      let qtdeSacos = parseInt(e.target.value);
      scheduleEdit.qtdeSacos = qtdeSacos;
      this.setState({ scheduleEdit });
    } catch (error) {
    }
  };
  onChangeDriverName = (e) => {
    let scheduleEdit = this.state.scheduleEdit;

    if (!scheduleEdit.driver) {
      scheduleEdit.driver = { name: "", documentNumber: "" }
    }

    scheduleEdit.driver.name = e.target.value;
    this.setState({ scheduleEdit });
  };

  onConfirm = () => {
    this.props.onConfirm(this.state.scheduleEdit);
  };

  onChangeDriverCPF = (e) => {
    let scheduleEdit = this.state.scheduleEdit;

    if (!scheduleEdit.driver) {
      scheduleEdit.driver = { name: "", documentNumber: "" }
    }

    scheduleEdit.driver.documentNumber = e.target.value;
    this.setState({ scheduleEdit });
  };

  onDropdownLoadingStatusChange = (e) => {
    let scheduleEdit = this.state.scheduleEdit;
    const { code, name } = e.target.value
    let loadingStatus = this.state.loadingStatus;
    loadingStatus = { code, name }
    scheduleEdit.loadingStatus = code;
    this.setState({ scheduleEdit, loadingStatus });
  };
  render() {
    moment.tz.setDefault("America/Sao_Paulo");
    const footer = (
      <div>
        <button
          title="Confirmar"
          disabled={!this.state.scheduleEnable || (this.props.scheduleLimite < this.state.scheduleEdit.operationTime && !this.state.scheduleEdit.oid)}
          className="lognex-btn-highlighted"
          onClick={this.onConfirm}
        >
          <i className="pi pi-check"></i>
        </button>
        <button title="Cancelar" className="lognex-btn-danger" onClick={this.props.onCancel}>
          <i className="pi pi-times"></i>
        </button>

        <ButtonDialog
          onClick={(e) => this.props.onClickRemoveSchedule(this.state.scheduleEdit)}
          button={{
            title: "Remover",
            className: "lognex-btn-danger",
            disabled: !this.state.scheduleEdit.oid,
          }}
          dialog={{
            header: "Confirmação",
            modal: false,
            message: "Deseja realmente remover o registro?",
          }}
        >
          <i className="pi pi-trash"></i>
        </ButtonDialog>
      </div>
    );

    return (
      <Dialog
        header={this.getTitulo()}
        footer={footer}
        visible={this.props.visibleEditSchedule}
        style={this.props.style || { width: "98vw", maxWidth: "900px" }}
        className="dialog-content"
        modal={true}
        onHide={this.props.onHide}
      >
        <div className="p-grid">
          <div className="p-col-2 form-field-container">
            <label>Código</label>
            <InputText value={this.state.scheduleEdit.oid || 0} disabled={true} />
          </div>

          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">Data Hora Inicio</label>
            
            <CustomDateField
              initialDate={this.state.scheduleEdit.startAt}
              timeZone="America/Sao_Paulo"
              onDateChange={(date) => {
                let scheduleEdit = this.state.scheduleEdit;
                scheduleEdit.startAt = date;
                scheduleEdit.operationTime = moment(this.state.scheduleEdit.endAt).diff(moment(this.state.scheduleEdit.startAt), "minutes");
                this.setState({
                  scheduleEdit
                })
              }}
            />

          </div>
          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">Data Hora Fim</label> 
            <CustomDateField
              initialDate={this.state.scheduleEdit.endAt}
              timeZone="America/Sao_Paulo"
              onDateChange={(e) => {
                let scheduleEdit = this.state.scheduleEdit;
                scheduleEdit.endAt = e.value;
                scheduleEdit.operationTime = moment(this.state.scheduleEdit.endAt).diff(moment(this.state.scheduleEdit.startAt), "minutes");
                this.setState({
                  scheduleEdit
                })
              }}
            /> 
          </div>

          <div className="p-col-4 form-field-container">
            <label className="lxRequiredField" title="Código separado por vírgula">Nro Shipment</label>
            <InputText value={this.state.shipmentCodeAux} title="Código separado por vírgula" disabled={this.state.scheduleEdit.oid} onChange={this.onChangeNrmShipmentCode} />
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-2 form-field-container">
            <label className="lxRequiredField">Embalagem</label>

            <Dropdown
              placeholder="Selecione"
              value={this.state.scheduleEdit.packagingType ?? ""}
              dataKey={"oid"}
              optionLabel="name"
              options={this.state.packagingType}
              onChange={this.onDropdownPackagingTypeChange}
            />
          </div>

          <div className="p-col-2 form-field-container">
            <label className="lxRequiredField">Tipo Veículo</label>

            <Dropdown
              placeholder="Selecione"
              disabled={!this.state.scheduleEdit.packagingType}
              value={this.state.scheduleEdit.vehicleType}
              dataKey={"oid"}
              optionLabel="name"
              options={this.state.vehicleType}
              onChange={this.onDropdownVehicleTypeChange}
            />
          </div>

          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">CPF</label>
            <InputText value={(this.state.scheduleEdit.driver && this.state.scheduleEdit.driver.documentNumber) || ""} onChange={this.onChangeDriverCPF} />
          </div>

          <div className="p-col-4 form-field-container">
            <label className="lxRequiredField">Motorista</label>
            <InputText value={(this.state.scheduleEdit.driver && this.state.scheduleEdit.driver.name) || ""} onChange={this.onChangeDriverName} />
          </div>

          <div className="p-col-4 form-field-container">
            <label className="lxRequiredField">Tipo de Operação</label>

            <Dropdown
              placeholder="Selecione"
              disabled={!this.state.scheduleEdit.vehicleType}
              value={this.state.scheduleEdit.operationType ?? ""}
              dataKey={"type"}
              optionLabel="label"
              options={this.state.operationType}
              onChange={this.onDropdownOperationTypeChange}
            />
          </div>

          <div className="p-col-8 form-field-container">
            <label>Transportadoras</label>

            <Dropdown
              placeholder="Selecione"
              value={this.state.scheduleEdit.carrier ?? ""}
              dataKey={"carrier"}
              optionLabel="name"
              options={this.props.selectedCarriers}
              onChange={this.onDropdownCarriersChange}
            />
          </div>
        </div>

        <div className="p-grid">
          <div className="p-col-3 form-field-container">
            <label className="lxRequiredField">Tempo da Operação</label>
            <InputText type="number" min={15} step={15} value={this.state.scheduleEdit.operationTime || 15} onChange={this.onChangeOperationTime} />
          </div>
          <div className="p-col-3 form-field-container">
            <label>Status Carregamento</label>

            <Dropdown
              placeholder="Selecione"
              value={this.state.loadingStatus}
              dataKey={"code"}
              optionLabel="name"
              options={this.state.loadingStatusList}
              onChange={this.onDropdownLoadingStatusChange}
            />
          </div>

          <div className="p-col-2 form-field-container">
            <label>Total Sacos</label>
            <InputText type="number" disabled={this.state.scheduleEdit.oid} value={this.state.scheduleEdit.qtdeSacos || 0} onChange={this.onChangeQtdeSacos} />
          </div>

          <div className="p-col-4 form-field-container">
            <label></label>
            <div className="warning_limits" style={{ display: `${this.state.scheduleEdit.oid ? "none" : "flex"}` }}>
              <span className={`${this.state.scheduleEdit.operationTime > this.props.scheduleLimite ? "warning_red" : ""}`}>
                {"Operação: "}
                {this.state.scheduleEdit.operationTime} minutos
              </span>
              <span>
                {"Limite máximo: "}
                {this.props.scheduleLimite} minutos
              </span>
            </div>
          </div>
        </div>

        {this.props.isLoadingShedulesHours && (
          <div className="loading-content">
            <div className="loader-center">
              <img src={loader} alt="loader"></img>
              <span>Verificando horários, aguarde um momento...</span>
            </div>
          </div>
        )}
        {!this.state.scheduleEnable && !this.props.isLoadingShedulesHours && (
          <div className="mensg-content">
            <div className="msg-center">
              <span>Não é possível criar uma agenda para esse horário, escolha outro horário!</span>
            </div>
          </div>
        )}
      </Dialog>
    );
  }
}
